<template>
  <div>
  <!--  新增标识点-->
    <div>
      <el-button type="primary" @click="diaSaveMarker = true">新增标识点</el-button>
    </div>
  <!--  现有标识点列表-->
    <div>
      <el-table :data="safe_markers" style="width: 100%">
        <el-table-column prop="bdstName" label="所属工区" width="180"/>
        <el-table-column prop="showName" label="展示名称" width="180"/>
        <el-table-column prop="showJW" label="经纬度" width="280" />
        <el-table-column prop="showIndex" label="是否展示在首页" width="180">
          <template #default="scope">
            <span v-if="scope.row.showIndex === 0">是</span>
            <span v-if="scope.row.showIndex === 1">否</span>
          </template>
        </el-table-column>
        <el-table-column label="操作">
          <template #default="scope">
            <el-popconfirm
                confirm-button-text="确认"
                cancel-button-text="取消"
                icon-color="#626AEF"
                title="是否确认删除?"
                @confirm="remove_safe_marker(scope.row)"
                @cancel=""
            >
              <template #reference>
                <el-button type="danger"> 删除 </el-button>
              </template>
            </el-popconfirm>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!--  分页-->
    <div style="float: right;margin-top: 20px;">
      <el-pagination v-model:currentPage="pagenum"
                     :small="true"
                     background layout="prev, pager, next" :total="totals">
      </el-pagination>
    </div>
  <!--  新增标识点-->
    <div>
      <el-dialog
          v-model="diaSaveMarker"
          title="新增单位"
          width="30%"
          :before-close="handleCloseSaveBasis">
        <div>
          <div style="margin-left: 30%;margin-top: 5%">

            <div style="display: flex">
              <el-tag>所&nbsp;属&nbsp;&nbsp;工&nbsp;&nbsp;区:&nbsp;</el-tag>
              <el-select v-model="bdst" class="m-2" placeholder="请选择所属工区" size="small" style="width: 60%"
              >
                <el-option
                    v-for="item in bdsts"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                />
              </el-select>
            </div>
            <div style="display: flex;margin-top: 2%">
              <el-tag>显&nbsp;示&nbsp;&nbsp;名&nbsp;&nbsp;称:&nbsp;</el-tag>
              <el-input v-model="show_name" size="small" style="width: 60%;" placeholder="请输入显示名称"></el-input>
            </div>
            <div style="display: flex;margin-top: 2%">
              <el-tag>&nbsp;&nbsp;&nbsp;经&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;度:&nbsp;&nbsp;&nbsp;&nbsp;</el-tag>
              <el-input v-model="show_x" size="small" style="width: 60%;" placeholder="请输入经度"></el-input>
            </div>
            <div style="display: flex;margin-top: 2%">
              <el-tag>&nbsp;&nbsp;&nbsp;纬&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;度:&nbsp;&nbsp;&nbsp;&nbsp;</el-tag>
              <el-input v-model="show_y" size="small" style="width: 60%;" placeholder="请输入纬度"></el-input>
            </div>
            <div style="display: flex;margin-top: 2%">
              <el-tag>是否在首页：</el-tag>
              <el-select v-model="show_index" class="m-2" placeholder="请选择是否选择展示在首页" size="small" style="width: 60%"
              >
                <el-option
                    v-for="item in show_indexs"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                />
              </el-select>
            </div>
            <div style="display: flex;margin-top: 2%">
              <el-tag>&nbsp;&nbsp;&nbsp;备&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;注:&nbsp;&nbsp;&nbsp;&nbsp;</el-tag>
              <el-input v-model="remarks" size="small" style="width: 60%;" placeholder="请输入备注"></el-input>
            </div>
          </div>
        </div>
        <template #footer>
          <span class="dialog-footer">
            <el-button type="primary " @click="save_safe_basis">提交</el-button>
          </span>
        </template>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import {ElMessage} from "element-plus";
import {Decrypt, Encrypt} from "@/utils/secret";

export default {
  name: "index",
  inject: ['reload'],
  data() {
    return {
      safe_markers: [],
      totals: 0,
      pagenum: 1,
      diaSaveMarker: false,
      bdst: '',
      bdsts: [
        {
          value: '1',
          label: '项目公司'
        },
        {
          value: '21',
          label: '二标一工区'
        },
        {
          value: '22',
          label: '二标二工区'
        },
        {
          value: '51',
          label: '五标一工区'
        },
        {
          value: '52',
          label: '五标二工区'
        },
        {
          value: '53',
          label: '五标三工区'
        },
        {
          value: '54',
          label: '五标四工区'
        },
        {
          value: '55',
          label: '五标五工区'
        },
        {
          value: '56',
          label: '五标六工区'
        },
        {
          value: '57',
          label: '五标七工区'
        }
      ],
      show_index: '',
      show_indexs: [
        {
          value: '0',
          label: '是'
        },
        {
          value: '1',
          label: '否'
        }
      ],
      remarks: '',
      show_x: '',
      show_y: '',
      show_name: ''
    }
  },
  created() {
    this.getAllMaekers()
  },
  methods: {
    getAllMaekers(){
      this.axios.get('/safeBasis/getAllMarkers', (response) => {
        this.safe_markers = response.obj.records
        this.totals = response.obj.pages * 10
        for (let i = 0; i < this.safe_markers.length; i++){
          this.safe_markers[i].showJW = Decrypt(this.safe_markers[i].showX) + ' , ' + Decrypt(this.safe_markers[i].showY)
        }
      },{
        pageCount: 1
      })
    },
    handleCloseSaveBasis(){
      this.bdst = ''
      this.show_y = ''
      this.show_x = ''
      this.remarks = ''
      this.show_index = ''
      this.show_name = ''
      this.diaSaveMarker = false
    },
    save_safe_basis(){
      const showX = Encrypt(this.show_x)
      const showY = Encrypt(this.show_y)
      this.axios.post('/safeBasis/saveBasis', (response) => {
        if (response.obj){
          this.bdst = ''
          this.show_y = ''
          this.show_x = ''
          this.remarks = ''
          this.show_index = ''
          this.show_name = ''
          ElMessage({
            message: '新增成功',
            type: 'success'
          })
        }else{
          ElMessage.error('新增失败，请联系科信部!')
        }
      },{
        bdst: this.bdst,
        show_x: showX,
        show_y: showY,
        remarks: this.remarks,
        show_index: this.show_index,
        show_name: this.show_name
      })
    },
    remove_safe_marker(item){
      this.axios.post('/safeBasis/removeById', (response) => {
        if (response.obj){
          ElMessage({
            message: '删除成功',
            type: 'success'
          })
          this.reload()
        }else{
          ElMessage.error('删除失败，请联系科信部!')
        }
      },{
        id: item.id
      })
    }
  },
  watch: {
    pagenum(){
      this.axios.get('/safeBasis/getAllMarkers', (response) => {
        this.safe_markers = response.obj.records
        this.totals = response.obj.pages * 10
      },{
        pageCount: this.pagenum
      })
    }
  }
}
</script>

<style scoped>

</style>
