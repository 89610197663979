<template>
  <div>
  <!--  查询条件、新增-->
    <div>
      <!--选择日期-->
      <el-date-picker
          v-model="danger_month"
          type="month"
          placeholder="请选择风险源月份"
          value-format="YYYY-MM"
          @change="change_month_level"
      />
    <!--  选择风险等级-->
      <el-select v-model="danger_level" class="m-2" placeholder="请选择风险源等级" style="width: 10%;margin-left: 2%;margin-right: 2%" @change="change_month_level"
      >
        <el-option
            v-for="item in danger_levels"
            :key="item.value"
            :label="item.label"
            :value="item.value"
        />
      </el-select>
    <!--  新增风险源-->
      <el-button type="primary" @click="diaSaveDanger = true" >新增风险源</el-button>
    </div>

  <!--  风险源列表-->
    <div>
      <el-table :data="dangers" style="width: 100%">
        <el-table-column prop="bdstName" label="所属工区" width="180"/>
        <el-table-column prop="operName" label="工点名称" width="180"/>
        <el-table-column prop="operWhere" label="位置" width="180"/>
        <el-table-column prop="c" label="风险等级" width="100">
          <template #default="scope">
            <span v-if="scope.row.dangerLevel === '0'">高度</span>
            <span v-if="scope.row.dangerLevel === '1'">中度</span>
            <span v-if="scope.row.dangerLevel === '2'">低度</span>
          </template>
        </el-table-column>
        <el-table-column prop="xmgsOwner" label="项目公司包保人" width="150"/>
        <el-table-column prop="bdstOwner" label="所属工区包保人" width="150"/>
        <el-table-column prop="dangerManage" label="管控措施" width="600"/>
        <el-table-column prop="dangerForecast" label="超前地质预报情况" width="620"/>
        <el-table-column label="操作">
          <template #default="scope">
            <el-popconfirm
                confirm-button-text="确认"
                cancel-button-text="取消"
                icon-color="#626AEF"
                title="是否确认删除?"
                @confirm="remove_safe_danger(scope.row)"
                @cancel=""
            >
              <template #reference>
                <el-button type="danger"> 删除 </el-button>
              </template>
            </el-popconfirm>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!--  分页-->
    <div style="float: right;margin-top: 20px;">
      <el-pagination v-model:currentPage="pagenum"
                     :small="true"
                     background layout="prev, pager, next" :total="totals">
      </el-pagination>
    </div>


  <!--  新增风险源弹窗-->
    <div>
      <el-dialog
          v-model="diaSaveDanger"
          title="新增单位"
          width="30%"
          :before-close="handleCloseSaveDanger">
        <div>
          <div style="margin-left: 30%;margin-top: 5%">

            <div style="display: flex">
              <el-tag>&nbsp;&nbsp;所&nbsp;属&nbsp;&nbsp;工&nbsp;&nbsp;区:&nbsp;&nbsp;&nbsp;&nbsp;</el-tag>
              <el-select v-model="save_bdst" class="m-2" placeholder="请选择所属工区" size="small" style="width: 60%" @change="change_bdst"
              >
                <el-option
                    v-for="item in bdsts"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                />
              </el-select>
            </div>
            <div style="display: flex;margin-top: 2%">
              <el-tag>&nbsp;&nbsp;工&nbsp;点&nbsp;&nbsp;名&nbsp;&nbsp;称:&nbsp;&nbsp;&nbsp;&nbsp;</el-tag>
              <el-select v-model="oper_name" class="m-2" placeholder="请选择工点名称" size="small" style="width: 60%"
              >
                <el-option
                    v-for="item in oper_names"
                    :key="item.operName"
                    :label="item.operName"
                    :value="item.operName"
                />
              </el-select>
            </div>
            <div style="display: flex;margin-top: 2%">
              <el-tag>&nbsp;&nbsp;风&nbsp;险&nbsp;&nbsp;月&nbsp;&nbsp;份:&nbsp;&nbsp;&nbsp;&nbsp;</el-tag>
              <el-date-picker
                  v-model="save_danger_month"
                  type="month"
                  placeholder="请选择风险源月份"
                  value-format="YYYY-MM"
                  size="small"
                  style="width: 60%"
                  @change="change_month"
              />
            </div>
            <div style="display: flex;margin-top: 2%">
              <el-tag>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;位&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;置:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</el-tag>
              <el-input v-model="danger_where" size="small" style="width: 60%;" placeholder="请输入位置"></el-input>
            </div>
            <div style="display: flex;margin-top: 2%">
              <el-tag>&nbsp;&nbsp;风&nbsp;&nbsp;&nbsp;险&nbsp;&nbsp;名&nbsp;&nbsp;称:&nbsp;&nbsp;&nbsp;</el-tag>
              <el-input v-model="danger_name" size="small" style="width: 60%;" placeholder="请输入风险名称"></el-input>
            </div>
            <div style="display: flex;margin-top: 2%">
              <el-tag>&nbsp;&nbsp;风&nbsp;&nbsp;&nbsp;险&nbsp;&nbsp;等&nbsp;&nbsp;级:&nbsp;&nbsp;&nbsp;</el-tag>
              <el-select v-model="save_danger_level" class="m-2" placeholder="请选择风险等级" size="small" style="width: 60%"
              >
                <el-option
                    v-for="item in danger_levels"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                />
              </el-select>
            </div>
            <div style="display: flex;margin-top: 2%">
              <el-tag>&nbsp;项目公司包保人&nbsp;&nbsp;:</el-tag>
              <el-input v-model="xmgs_owner" size="small" style="width: 60%;" placeholder="请输入项目公司包保人"></el-input>
            </div>
            <div style="display: flex;margin-top: 2%">
              <el-tag>&nbsp;所属工区包保人&nbsp;&nbsp;:</el-tag>
              <el-input v-model="bdst_owner" size="small" style="width: 60%;" placeholder="请输入所属工区包保人"></el-input>
            </div>
            <div style="display: flex;margin-top: 2%">
              <el-tag>&nbsp;&nbsp;管&nbsp;&nbsp;&nbsp;&nbsp;控&nbsp;&nbsp;&nbsp;&nbsp;措&nbsp;&nbsp;&nbsp;&nbsp;施:</el-tag>
              <el-input v-model="danger_anage" autosize type="textarea" size="small" style="width: 60%;" placeholder="请输入管控措施"></el-input>
            </div>
            <div style="display: flex;margin-top: 2%">
              <el-tag>超前地质预报情况:</el-tag>
              <el-input v-model="danger_forecast" autosize type="textarea" size="small" style="width: 60%;" placeholder="请输入超前地质预报情况"></el-input>
            </div>
          </div>
        </div>
        <template #footer>
          <span class="dialog-footer">
            <el-button type="primary " @click="save_safe_danger">提交</el-button>
          </span>
        </template>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import {ElMessage} from "element-plus";
import {mapGetters} from "vuex";
import {setNowTimes} from "@/utils/time";

export default {
  name: "index",
  inject: ['reload'],
  computed: {
    ...mapGetters(['GET_USER'])
  },
  data() {
    return {
      danger_month: '',
      danger_level: '',
      danger_levels: [
        {
          value: '0',
          label: '高度'
        },
        {
          value: '1',
          label: '中度'
        },
        {
          value: '2',
          label: '低度'
        }
      ],
      diaSaveDanger: false,
      bdst: '',
      bdsts: [
        {
          value: '21',
          label: '二标一工区'
        },
        {
          value: '22',
          label: '二标二工区'
        },
        {
          value: '51',
          label: '五标一工区'
        },
        {
          value: '52',
          label: '五标二工区'
        },
        {
          value: '53',
          label: '五标三工区'
        },
        {
          value: '54',
          label: '五标四工区'
        },
        {
          value: '55',
          label: '五标五工区'
        },
        {
          value: '56',
          label: '五标六工区'
        },
        {
          value: '57',
          label: '五标七工区'
        }
      ],
      oper_names: [],
      oper_name: '',
      save_danger_level: '',
      danger_where: '',
      danger_forecast: '',
      danger_anage: '',
      bdst_owner: '',
      xmgs_owner: '',
      save_bdst: '',
      save_danger_month: '',
      danger_name: '',
      totals: 0,
      pagenum: 1,
      dangers: []
    }
  },
  created() {
    this.get_all_danger()
  },
  methods: {
    get_all_danger(){
      this.axios.post('/safeRiskSource/getDangerAll', (response) => {
        this.dangers = response.obj.records
        this.totals = response.obj.pages * 10
      },{
        pageCount: this.pagenum,
        month: this.danger_month,
        danger_level: this.danger_level
      })
    },
    change_month_level(){
      this.axios.post('/safeRiskSource/getDangerAll', (response) => {
        this.dangers = response.obj.records
        this.totals = response.obj.pages * 10
      },{
        pageCount: 1,
        month: this.danger_month,
        danger_level: this.danger_level
      })
    },
    change_bdst(){
      this.axios.post('/operName/getOperByBdst', (response) => {
        this.oper_names = response.obj
      },{
        bdst: this.save_bdst
      })
    },
    handleCloseSaveDanger(){
      this.save_bdst = ''
      this.save_danger_month = ''
      this.oper_name = ''
      this.danger_where = ''
      this.danger_name = ''
      this.save_danger_level = ''
      this.xmgs_owner = ''
      this.bdst_owner = ''
      this.danger_anage = ''
      this.danger_forecast = ''
      this.diaSaveDanger = false
    },
    save_safe_danger(){
      this.axios.post('/safeRiskSource/saveRiskSource', (response) => {
        if (response.obj){
          this.save_bdst = ''
          this.save_danger_month = ''
          this.oper_name = ''
          this.danger_where = ''
          this.danger_name = ''
          this.save_danger_level = ''
          this.xmgs_owner = ''
          this.bdst_owner = ''
          this.danger_anage = ''
          this.danger_forecast = ''
          ElMessage({
            message: '新增风险源成功!',
            type: 'success'
          })
        }else{
          ElMessage.error('新增风险源失败，请联系科信部!')
        }
      },{
        bdst: this.save_bdst,
        danger_month: this.save_danger_month,
        oper_name: this.oper_name,
        oper_where: this.danger_where,
        danger_name: this.danger_name,
        danger_level: this.save_danger_level,
        xmgs_owner: this.xmgs_owner,
        bdst_owner: this.bdst_owner,
        danger_manage: this.danger_anage,
        danger_forecast: this.danger_forecast,
        up_user: this.GET_USER,
        up_time: setNowTimes(new Date())
      })
    },
    remove_safe_danger(item){
      this.axios.post('/safeRiskSource/removeById', (response) => {
        if (response.obj){
          ElMessage({
            message: '删除风险源成功!',
            type: 'success'
          })
          this.reload()
        }else{
          ElMessage.error('删除风险源失败，请联系科信部!')
        }
      },{
        id: item.id
      })
    }
  },
  watch: {
    pagenum(){
      this.axios.post('/safeRiskSource/getDangerAll', (response) => {
        this.dangers = response.obj.records
        this.totals = response.obj.pages * 10
      },{
        pageCount: this.pagenum,
        month: this.danger_month,
        danger_level: this.danger_level
      })
    }
  }
}
</script>

<style scoped>

</style>
